<template>
  <div>
    <div class="p-1 sidebar-header">
      <b-button
        variant="flat-success"
        class="btn-icon text-dark"
        @click="hide"
      >
        <feather-icon icon="XIcon" />
      </b-button>
      <h4 id="sidebar-no-header-title text-dark pl-1">
        Opções do card
      </h4>
    </div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      :variant="variant"
    >
      <b-card
        no-body
        class="mb-2"
        @mouseover="mouseOver = true"
        @mouseout="mouseOver = false"
      >
        <b-card-body>
          <b-tabs>
            <b-tab
              v-if="card.column !== 'finished'
                && (
                  card.role === 'success'
                  && ['onboarding', 'following', 'follow_up', 'new_subscriber', 'future_schedule'].find(f => f === card.column)
                ) || (
                  card.role === 'sales'
                ) || (
                  card.role === 'sales_sdr'
                ) || (
                  card.role === 'relationship'
                  && ['schedule', 'following', 'follow_up', 'onboarding', 'transition'].find(f => f === card.column)
                ) || (
                  card.role === 'service_qualification'
                  && ['double', 'active_review', 'smarties'].find(f => f === card.column)
                ) || (
                  !!card.additional
                  && card.column === 'sanitation'
                ) || (
                  !!card.additional
                  && card.column === 'issue_success'
                ) || (
                  card.role === 'mentoring'
                )
              "
            >
              <template #title>
                <feather-icon icon="EditIcon" />
                <span>Ações</span>
              </template>

              <!--service_qualification-->
              <div
                v-if="
                  card.role === 'service_qualification'
                "
              >
                <b-row
                  v-if="['smarties'].find(f => f === card.column)"
                >
                  <b-col>
                    <p>
                      Marcar o usuário como falso positivo até a próxima alteração do perfil:
                    </p>
                    <div class="text-right mt-1">
                      <b-button
                        variant="primary"
                        @click="handleRegisterContact"
                      >
                        Falso positivo
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-if="['double'].find(f => f === card.column)"
                >
                  <b-col>
                    <p>
                      Após finalizar a migração do usuário clique no botão abaixo:
                    </p>
                    <div class="text-right mt-1">
                      <b-button
                        variant="primary"
                        @click="handleRegisterContact"
                      >
                        Finalizar
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-if="['active_review'].find(f => f === card.column)"
                >
                  <b-col>
                    <p>
                      Após finalizar a revisão do usuário clique no botão abaixo:
                    </p>
                    <div class="text-right mt-1">
                      <b-button
                        variant="primary"
                        @click="handleRegisterContact"
                      >
                        Finalizar
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div v-else-if="card.additional">
                <b-form>
                  <validation-observer ref="rulesAdditionalContactMade">
                    <b-row>
                      <b-col cols="10">
                        <b-form-radio-group
                          v-model="contactMade"
                          :options="options"
                          class="demo-inline-spacing"
                        />
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="contactMade === 'yes' && card.column === 'sanitation'"
                      class="mt-1"
                    >
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          :rules="'required'"
                        >
                          <b-form-group
                            label="Atuação"
                          >
                            <b-form-select
                              v-model="sanitationCustomerStatus"
                              :options="sanitationCustomerStatusOptions"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="sanitationCustomerStatus === 'active_interested'"
                        cols="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :rules="'required'"
                        >
                          <b-form-group
                            label="Enviar link da mensagem por"
                          >
                            <b-form-checkbox-group
                              v-model="channelsToSend"
                              :options="sanitationCustomerStatusActiveInterestedOptions"
                              switches
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row
                      v-else-if="contactMade === 'no'"
                      class="mt-1"
                    >
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          :rules="'required'"
                        >
                          <b-form-group
                            label="Motivo"
                          >
                            <b-form-select
                              v-model="sanitationCustomerNoContactReason"
                              :options="sanitationCustomerNoContactReasonOptions"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <validation-provider
                      v-if="showAnnotationField"
                      #default="{ errors }"
                      rules="required|min:10"
                    >
                      <b-form-group
                        class="mt-2"
                        label="Anotação"
                        label-for="annotation"
                      >
                        <b-form-textarea
                          id="annotation"
                          v-model="annotation"
                          rows="6"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <div
                      v-if="showScheduleDateField"
                      class="mt-2"
                    >
                      <validation-provider
                        v-if="!showScheduleWithAgendador"
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="Selecione a data"
                          label-for="new_date"
                        >
                          <flat-pickr
                            id="schedule_date"
                            ref="datePicker"
                            v-model="dateNtim"
                            class="form-control"
                            :config="{
                              inline:true,
                              allowInput:false,
                              position: 'auto center',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              altFormat: 'd/m \\à\\s H:i',
                              altInput: true,
                              minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                              time_24hr: true,
                            }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                      <div v-if="showScheduleWithAgendador">
                        <validation-observer ref="rulesDateTime">
                          <b-form-group
                            label="Selecione a data"
                            label-for="new_date"
                          >
                            <b-form-input
                              :value="selectedDate"
                              disabled
                            />
                            <div
                              v-if="isLoadingEvent"
                              class="text-center mt-2"
                            >
                              <b-spinner />
                              <p>Carregando horários...</p>
                            </div>
                            <flat-pickr
                              v-else
                              id="schedule_date"
                              ref="datePicker"
                              v-model="dateNtim"
                              class="form-control"
                              :config="{
                                enable: enableDates,
                                inline:true,
                                allowInput:false,
                                enableTime: false,
                                position: 'auto center',
                                dateFormat: 'Y-m-d',
                                altFormat: 'd/m',
                                altInput: true,
                                altInputClass: 'd-none',
                                minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                                time_24hr: true,
                              }"
                            />
                          </b-form-group>

                          <validation-provider
                            #default="{ errors }"
                            rules="required|min:4"
                          >
                            <b-form-group
                              label="Horário"
                            >
                              <b-form-select
                                id="time"
                                v-model="dateTime"
                                :options="getTimesToTour"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </validation-observer>
                      </div>
                    </div>
                  </validation-observer>

                </b-form>

                <b-row>
                  <b-col class="text-right mt-1">
                    <b-button
                      variant="primary"
                      :disabled="!contactMade"
                      @click="handleAdditionalRegisterContact"
                    >
                      Salvar
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-form
                v-else
              >
                <validation-observer ref="rulesForm">

                  <b-row>
                    <b-col cols="10">
                      <b-form-radio-group
                        v-model="contactMade"
                        :options="options"
                        class="demo-inline-spacing"
                        name="radio-validation"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <validation-provider
                        v-if="showDescriptionField"
                        #default="{ errors }"
                        :rules="rulesDescriptionField"
                      >
                        <b-form-group
                          label="Descrição do card"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="description"
                            v-model="description"
                            rows="6"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                      <div v-if="showScheduleDateField">
                        <validation-provider
                          v-if="!showScheduleWithAgendador"
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            label="Selecione a data"
                            label-for="new_date"
                          >
                            <flat-pickr
                              id="schedule_date"
                              ref="datePicker"
                              v-model="dateNtim"
                              class="form-control"
                              :config="{
                                inline:true,
                                allowInput:false,
                                position: 'auto center',
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i:S',
                                altFormat: 'd/m \\à\\s H:i',
                                altInput: true,
                                minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                                time_24hr: true,
                              }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                        <div v-if="showScheduleWithAgendador">
                          <validation-observer ref="rulesDateTime">
                            <b-form-group
                              label="Selecione a data"
                              label-for="new_date"
                            >
                              <b-form-input
                                :value="selectedDate"
                                disabled
                              />
                              <div
                                v-if="isLoadingEvent"
                                class="text-center mt-2"
                              >
                                <b-spinner />
                                <p>Carregando horários...</p>
                              </div>
                              <flat-pickr
                                v-else
                                id="schedule_date"
                                ref="datePicker"
                                v-model="dateNtim"
                                class="form-control"
                                :config="{
                                  enable: enableDates,
                                  inline:true,
                                  allowInput:false,
                                  position: 'auto center',
                                  dateFormat: 'Y-m-d',
                                  altFormat: 'd/m \\à\\s',
                                  altInput: true,
                                  altInputClass: 'd-none',
                                  minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                                  time_24hr: true,
                                }"
                              />
                            </b-form-group>

                            <validation-provider
                              #default="{ errors }"
                              rules="required|min:4"
                            >
                              <b-form-group
                                label="Horário"
                              >
                                <b-form-select
                                  id="time"
                                  v-model="dateTime"
                                  :options="getTimesToTour"
                                  :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                          </validation-observer>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="contactMade === 'yes' || contactMade === 'new' || contactMade === 'future-schedule' || (card.role === 'mentoring' && contactMade === 'schedule')"
                    class="mt-1"
                  >
                    <b-col cols="12">
                      <validation-observer
                        ref="rulesContactMade"
                      >
                        <validation-provider
                          v-if="!showDemographics && showAnnotationField"
                          #default="{ errors }"
                          :rules="card.role === 'sales' ? 'min:10' : 'required|min:10'"
                        >
                          <b-form-group
                            label="Observação"
                            label-for="annotation"
                          >
                            <b-form-textarea
                              id="annotation"
                              v-model="annotation"
                              rows="6"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-if="showBiasField"
                          #default="{ errors }"
                          rules="required|min:2"
                        >
                          <b-form-group
                            label="Viés"
                            label-for="type"
                          >
                            <b-form-select
                              id="bias"
                              v-model="annotationBias"
                              :options="annotationBiasOptions"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-if="showChannelField"
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            label="Forma de Contato"
                            label-for="channel"
                          >
                            <b-form-select
                              id="channel"
                              v-model="annotationChannel"
                              :options="listAnnotationChannels"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                        <b-row
                          v-if="card.role === 'sales' && contactMade === 'yes'"
                        >
                          <b-col cols="12">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label="Qualificação do lead"
                                label-for="lead_qualification"
                              >
                                <b-form-input
                                  v-model="salesLeadQualification"
                                  class="d-none"
                                />
                                <b-dropdown
                                  id="lead_qualification"
                                  variant="outline-secondary"
                                  class="full-width"
                                >
                                  <template #button-content>
                                    <span v-if="salesLeadQualification === ''">Selecione</span>
                                    <feather-icon
                                      v-for="star in parseInt((salesLeadQualification || card.sales_lead_qualification || '0').replace(/[^0-9]/g, ''), 10)"
                                      v-else
                                      :key="star"
                                      icon="StarIcon"
                                      fill="yellow"
                                      stroke="gold"
                                    />
                                  </template>
                                  <b-dropdown-item
                                    @click="salesLeadQualification = ''"
                                  >
                                    Selecione
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    v-for="rating in 3"
                                    :key="rating"
                                    @click="salesLeadQualification = ''+rating"
                                  >
                                    <feather-icon
                                      v-for="star in rating"
                                      :key="star"
                                      icon="StarIcon"
                                      fill="yellow"
                                      stroke="gold"
                                    />
                                  </b-dropdown-item>
                                </b-dropdown>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-row>
                                <b-col cols="9">
                                  <b-form-group>
                                    <b-form-radio-group
                                      v-model="salesContact"
                                      :options="roleSalesContactOptions"
                                      class="demo-inline-spacing"
                                      name="salesContact"
                                      @change="changeSalesContact"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </validation-provider>

                            <validation-provider
                              v-if="salesContact !== 'no'"
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label="Pipeline"
                                label-for="pipeline"
                              >
                                <b-form-select
                                  id="pipeline"
                                  v-model="salesPipeline"
                                  :options="roleSalesPipelines"
                                />
                              </b-form-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                          </b-col>
                        </b-row>

                        <b-row
                          v-if="card.role === 'sales_sdr' && contactMade === 'yes'"
                        >
                          <b-col cols="12">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label="Qualificação do lead"
                                label-for="sdr_lead_qualification"
                              >
                                <b-form-select
                                  id="sdr_lead_qualification"
                                  v-model="leadQualification"
                                  :options="roleSalesSdrQualification"
                                  :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>

                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label="Tem interesse no uso"
                                label-for="sdr_lead_use_interest"
                              >
                                <b-form-select
                                  id="sdr_lead_use_interest"
                                  v-model="leadUseInterest"
                                  :options="roleSalesSdrLeadInterest"
                                  :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>

                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group>
                                <b-form-radio-group
                                  v-model="salesContact"
                                  :options="roleSalesSdrContactOptions"
                                  class="demo-inline-spacing d-flex flex-column align-items-start"
                                  name="salesContact"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>

                        <validation-provider
                          v-if="showAssignToField"
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            label="Atribuir a"
                            label-for="assign_to"
                          >
                            <b-form-select
                              id="assign_to"
                              v-model="annotationAssignTo"
                              :options="roleRelationshipAnnotationAssignTo"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-if="showNewDateField"
                          #default="{ errors }"
                          :rules="card.role === 'sales' ? 'required' : undefined"
                        >
                          <b-form-group
                            label="Nova data de contato"
                            label-for="new_date"
                          >
                            <flat-pickr
                              id="new_date"
                              ref="datePicker"
                              v-model="dateNtim"
                              class="form-control"
                              :config="{
                                inline:true,
                                allowInput:false,
                                position: 'auto center',
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i:S',
                                altFormat: 'd/m \\à\\s H:i',
                                altInput: true,
                                minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                                time_24hr: true,
                                ...card.role === 'sales' ? {
                                  maxDate: new Date().setDate( (new Date()).getDate() + 30 ),
                                } : {},
                              }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </validation-observer>
                    </b-col>
                  </b-row>

                  <b-row v-if="showDemographics">
                    <b-col>
                      <hr>
                    </b-col>
                    <b-col cols="12">
                      <user-list-demographic
                        ref="userListDemographic"
                        :save-button="false"
                        :no-padding="true"
                        :user-id="card.user_id"
                        :user-type="card.user.type"
                        :user-responsible="card.user.name"
                        :sidebar-role="card.role"
                        @isLoading="demographicIsLoading"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right mt-1">
                      <b-button
                        variant="primary"
                        :disabled="!contactMade || isLoadingEvent || (showDemographics && isLoadingDemographics)"
                        @click="handleRegisterContact"
                      >
                        Salvar
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-form>
            </b-tab>

            <b-tab v-if="!simple">
              <template #title>
                <feather-icon icon="FileTextIcon" />
                <span>Anotações</span>
              </template>

              <div class="activity-items">
                <user-list-annotation
                  name="userListAnnotation"
                  :user-id="card.user_id"
                  :manager-id="card.account_manager_id"
                  small
                />
              </div>
            </b-tab>

            <b-tab v-if="!simple">
              <template #title>
                <feather-icon icon="ActivityIcon" />
                <span>Atividades</span>
              </template>

              <div class="activity-items">
                <user-list-activities
                  name="userListActivities"
                  :user-id="card.user_id"
                  :small="true"
                />
              </div>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BButton,
  BCardBody,
  BTab,
  BTabs,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BSpinner,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import KanbanService from '@/services/kanbanService'
import SchedulerService from '@/services/schedulerService'
import UserService from '@/services/userService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  roleRelationshipAnnotationAssignTo,
  annotationChannels,
  annotationBias as annotationBiasOptions,
  roleSalesContactOptions,
  roleSalesSdrContactOptions,
  roleSalesSdrQualification,
  roleSalesSdrLeadInterest,
  withEmptyOptionFirst,
  roleSalesPipelines,
} from '@/utils/options'
import flatPickr from 'vue-flatpickr-component'
import UserListAnnotation from '../components/UserListAnnotation.vue'
import UserListActivities from '../components/UserListActivities.vue'
import UserListDemographic from '../components/UserListDemographic.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSpinner,
    flatPickr,
    UserListActivities,
    UserListAnnotation,
    UserListDemographic,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      kanbanService: null,
      userService: null,
      mouseOver: false,
      dateNtim: null,
      activeFilters: [],
      description: this.card.description ? this.card.description : '',
      annotation: '',
      annotationAssignTo: '',
      leadQualification: '',
      leadUseInterest: '',
      annotationChannel: '',
      salesLeadQualification: '',
      salesPipeline: '',
      annotationBias: '',
      enableDates: [{
        from: '2023-01-01',
        to: '2030-01-01',
      }],
      dateTime: '',
      isLoadingEvent: false,
      isLoading: false,
      isLoadingDemographics: false,
      contactMade: null,
      sanitationCustomerStatus: '',
      sanitationCustomerNoContactReason: '',
      salesContact: null,
      required,
      ToastificationContent,
      annotationChannels,
      annotationBiasOptions,
      roleSalesContactOptions,
      roleSalesSdrContactOptions,
      roleRelationshipAnnotationAssignTo,
      roleSalesSdrQualification,
      roleSalesSdrLeadInterest,
      roleSalesPipelines,
      channelsToSend: [],
    }
  },

  computed: {
    variant() {
      return document.body.classList.contains('dark-layout') ? 'dark' : undefined
    },
    options() {
      const r = []

      if (this.card.role === 'relationship' && this.card.column === 'schedule') {
        r.push({ text: 'Agendar Onboarding', value: 'schedule' })
        r.push({ text: 'Não consegui contato', value: 'schedule-no' })
        r.push({ text: 'Não agendar onboarding', value: 'do-not-want-onboarding' })
        r.push({ text: 'Tour em ligação', value: 'add-schedule' })
      } else if (this.card.role === 'mentoring' && this.card.column === 'qualified') {
        r.push({ text: 'Agendar Reunião Alinhamento', value: 'schedule' })
        r.push({ text: 'Não consegui contato', value: 'not-contacted' })
        r.push({ text: 'Consegui contato e irá agendar', value: 'contacted-link-sent' })
        r.push({ text: 'Reunião em ligação', value: 'add-schedule' })
        r.push({ text: 'Sem interesse', value: 'not-interest' })
      } else if (this.card.role === 'mentoring' && this.card.column === 'meet') {
        r.push({ text: 'Reunião realizada', value: 'schedule' })
        r.push({ text: 'Agendar novo horário', value: 'new-schedule' })
        r.push({ text: 'Desistiu/Sem interesse', value: 'not-interest' })
      } else if (this.card.role === 'mentoring' && this.card.column === 'follow') {
        r.push({ text: 'Tem interesse', value: 'schedule' })
        r.push({ text: 'Agendar novo horário', value: 'new-schedule' })
        r.push({ text: 'Desistiu/Sem interesse', value: 'not-interest' })
      } else if (this.card.role === 'mentoring' && this.card.column === 'qbr') {
        r.push({ text: 'Tem interesse', value: 'schedule' })
        r.push({ text: 'Agendar novo horário', value: 'new-schedule' })
        r.push({ text: 'Desistiu/Sem interesse', value: 'not-interest' })
      } else {
        r.push({ text: 'Consegui contato', value: 'yes' })
        if (this.card.role === 'success') {
          r.push({ text: 'Agendar nova ligação', value: 'new' })
        }

        if (this.card.role === 'relationship' && ['onboarding', 'following', 'follow_up'].includes(this.card.column)) {
          r.push({ text: 'Agendar nova ligação', value: 'hide-until' })
        }

        r.push({ text: 'Não consegui contato', value: 'no' })

        if (this.card.column === 'issue_success') {
          r.push({ text: 'Agendar ligação', value: 'future-schedule' })
        }

        if (this.card.role === 'relationship' && this.card.column === 'following') {
          r.push({ text: 'Tour em ligação', value: 'add-schedule' })
        }

        if (this.card.role === 'sales_sdr') {
          r.push({ text: 'Cancelar cadastro', value: 'cancel-registration' })
          r.push({ text: 'Agendar nova ligação', value: 'future-schedule' })
        }
      }
      return r
    },

    sanitationCustomerStatusOptions() {
      const options = withEmptyOptionFirst([
        { value: 'inactive', text: 'Não está mais atuando' },
      ])

      if (this.card.tag === 'campaign_70_10') {
        options.push({ value: 'active_not_interested', text: 'Atuando e não quer link da mensagem' })
        options.push({ value: 'active_interested', text: 'Atuando e quer link da mensagem' })
      } else {
        options.push({ value: 'active_not_interested', text: 'Atuando' })
      }

      return options
    },

    sanitationCustomerNoContactReasonOptions() {
      const options = withEmptyOptionFirst([
        { value: 'unanswered', text: 'Não completa a ligação' },
        { value: 'direct_to_po_box', text: 'Caixa postal direto' },
        { value: 'does_not_exist', text: 'Telefone não existe' },
        { value: 'wrong_phone', text: 'Telefone não é do cliente' },
      ])

      return options
    },

    sanitationCustomerStatusActiveInterestedOptions() {
      return [
        { value: 'whatsapp', text: 'Whatsapp' },
        { value: 'email', text: 'Email' },
      ]
    },

    showAssignToField() {
      return false
    },

    showBiasField() {
      return false
    },

    showDemographics() {
      if (['success', 'relationship', 'sales'].includes(this.card.role) && this.contactMade === 'yes') {
        return true
      }

      return false
    },

    showAnnotationField() {
      if (this.card.role === 'success' && this.contactMade === 'new') {
        return false
      }

      if (this.card.additional) {
        if (this.card.column === 'issue_success' && this.contactMade === 'yes') {
          return true
        }
        return false
      }

      return true
    },

    listAnnotationChannels() {
      if (
        (this.card.role === 'relationship' && this.card.column === 'onboarding')
        || (this.card.role === 'success' && this.card.column === 'new_subscriber')
      ) {
        return this.annotationChannels.filter(f => f.value === 'phone')
      }

      if (this.card.role === 'sales') {
        return this.annotationChannels.filter(f => f.value !== 'chat')
      }

      if (
        (this.card.role === 'relationship' && this.card.column === 'follow_up')
        || (this.card.role === 'success' && this.card.column === 'follow_up')
      ) {
        return this.annotationChannels.filter(f => ['email', 'phone', 'whats'].includes(f.value))
      }

      return this.annotationChannels
    },

    showChannelField() {
      if (this.card.role === 'sales_sdr') {
        return false
      }

      if (this.card.role === 'mentoring') {
        return false
      }

      if (this.card.role === 'success' && this.card.column !== 'new_subscriber' && this.card.column !== 'follow_up') {
        return false
      }

      return true
    },
    showNewDateField() {
      if (this.card.role === 'success' && this.contactMade === 'new') {
        return true
      }

      if (this.card.role === 'sales' && this.contactMade === 'yes' && this.salesContact === 'schedule') {
        return true
      }

      if (this.card.role === 'relationship' && this.contactMade === 'schedule') {
        return true
      }

      return false
    },

    showScheduleWithAgendador() {
      if (this.card.role === 'relationship' && ['onboarding', 'schedule'].includes(this.card.column)) {
        return true
      }

      if (this.card.role === 'mentoring' && ['schedule', 'new-schedule'].includes(this.contactMade)) {
        return true
      }

      return false
    },

    showScheduleDateField() {
      if (this.card.role === 'relationship' && this.contactMade === 'schedule') {
        return true
      }

      if (this.card.role === 'relationship' && this.contactMade === 'hide-until') {
        return true
      }

      if (this.card.role === 'sales_sdr' && this.contactMade === 'future-schedule') {
        return true
      }

      if (this.card.column === 'issue_success' && this.contactMade === 'future-schedule') {
        return true
      }

      if (this.card.role === 'mentoring' && ['schedule', 'new-schedule'].includes(this.contactMade)) {
        return true
      }

      return false
    },

    rulesDescriptionField() {
      if (
        (
          this.card.role === 'mentoring'
          && this.card.column === 'qualified'
          && this.contactMade === 'not-interest'
        ) || (
          this.card.role === 'mentoring'
              && this.card.column === 'meet'
              && this.contactMade === 'not-interest'
        ) || (
          this.card.role === 'mentoring'
              && this.card.column === 'follow'
              && this.contactMade === 'not-interest'
        ) || (
          this.card.role === 'mentoring'
              && this.card.column === 'qbr'
              && this.contactMade === 'not-interest'
        )
      ) {
        return 'required|min:10'
      }

      return ''
    },

    showDescriptionField() {
      if (
        (
          this.card.role === 'mentoring'
            && this.card.column === 'qualified'
            && ['schedule', 'do-not-want-onboarding', 'not-interest'].includes(this.contactMade)
        ) || (
          this.card.role === 'mentoring'
             && this.card.column === 'meet'
             && this.contactMade === 'not-interest'
        ) || (
          this.card.role === 'mentoring'
             && this.card.column === 'follow'
             && this.contactMade === 'not-interest'
        ) || (
          this.card.role === 'mentoring'
             && this.card.column === 'qbr'
             && this.contactMade === 'not-interest'
        )
      ) {
        return true
      }

      if (
        ['relationship', 'success'].includes(this.card.role)
        && (
          this.showNewDateField
          || this.showScheduleDateField
        )
      ) {
        return true
      }

      return false
    },

    getTimesToTour() {
      if (this.enableDates) {
        return this.enableDates.filter(f => (typeof f === 'string' && f.includes(this.dateNtim))).map(m => ({
          text: m.replace(this.dateNtim, ''),
          value: m.replace(this.dateNtim, ''),
        }))
      }

      return []
    },

    selectedDate() {
      if (this.dateNtim) {
        if (this.dateTime) {
          return `${this.dateNtim.replace(/([0-9]{4})-([0-9]{2})-([0-9]{2})/, '$3/$2/$1')} às ${this.dateTime}`
        }

        return 'Selecione um Horário'
      }
      return 'Selecione um dia'
    },
  },
  watch: {
    dateNtim() {
      this.dateTime = ''
    },

    showDemographics(val) {
      if (val) {
        this.$nextTick(() => {
          this.initDemographic()
        })
      }
    },
  },
  mounted() {
  },
  async created() {
    this.kanbanService = new KanbanService()
    this.userService = new UserService()
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
    if (
      (this.card.role === 'relationship' && ['schedule', 'onboarding'].includes(this.card.column))
        || (this.card.role === 'mentoring' && ['qualified', 'meet', 'follow', 'qbr'].includes(this.card.column))
    ) {
      const days = ['follow', 'qbr'].includes(this.card.column) ? 90 : 30
      this.getEventByManager(days)
    }
  },
  methods: {
    changeSalesContact(event) {
      if (event === 'yes') {
        this.salesPipeline = 'negotiation'
      }
      if (event === 'schedule') {
        this.salesPipeline = 'qualification'
      }
      if (event === 'negotiation') {
        this.salesPipeline = 'qualification'
      }
    },

    getEventByManager(days) {
      this.isLoadingEvent = true
      return SchedulerService.getEventByManager(this.card.account_manager_id, 'tour', days).then(response => {
        this.enableDates = response.data.data.event.available_times
      }).finally(() => {
        this.isLoadingEvent = false
      })
    },

    initDemographic() {
      if (this.showDemographics) {
        if (this.$refs.userListDemographic) {
          if (!this.$refs.userListDemographic.initExecuted) {
            this.$refs.userListDemographic.init()
          }
        } else {
          this.$nextTick(() => {
            this.initDemographic()
          })
        }
      }
    },

    demographicIsLoading(val) {
      this.isLoadingDemographics = val
    },

    selectFilter(filter) {
      if (this.activeFilters.filter(f => f === filter).length === 0) {
        this.activeFilters.push(filter)
      } else {
        this.activeFilters = this.activeFilters.filter(f => f !== filter)
      }
    },

    hide() {
      if (!this.isLoading) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar')
      }
    },

    toast(title, text, success) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: success ? 'EditIcon' : 'BellIcon',
            variant: success ? 'info' : 'danger',
          },
        },
        {
          position: 'top-center',
        },
      )
    },

    handleAdditionalRegisterContact() {
      this.$emit('prevent-close-sidebar', true)

      const contactMade = (this.contactMade === 'yes' || this.contactMade === 'new') ? 1 : 0

      const {
        sanitationCustomerStatus,
        channelsToSend,
        sanitationCustomerNoContactReason,
      } = this

      if (contactMade) {
        this.$refs.rulesAdditionalContactMade.validate().then(success => {
          if (!success) {
            return
          }

          this.isLoading = true

          let data = {}
          let method = null
          if (this.card.column === 'issue_success') {
            data = {
              column: this.card.column,
              annotation: this.annotation,
              status: 'contacted',
            }
            method = this.kanbanService.contactedAdditionalMentoring
          } else {
            data = {
              column: this.card.column,
              status: sanitationCustomerStatus,
              ...channelsToSend && channelsToSend.length > 0 ? { channel: channelsToSend } : {},
            }
            method = this.kanbanService.contactedAdditional
          }

          method(this.card.id, data).then(() => {
            this.card.column = 'refresh' // força a retirada do card do kanban
            this.$emit('kanban-refresh')
            this.isLoading = false
            this.hide()
          }).catch(() => {
            this.toast('Erro!', 'Não foi possível registrar o contato', false)
          }).finally(() => {
            this.isLoading = false
          })
        })
      } else {
        this.$refs.rulesAdditionalContactMade.validate().then(success => {
          if (!success) {
            return
          }

          let data = {}
          let method = null
          if (this.card.column === 'issue_success') {
            data = {
              reason: sanitationCustomerNoContactReason,
            }
            if (this.contactMade === 'future-schedule') {
              data.new_date = this.dateNtim
            }

            method = this.kanbanService.postponeAdditionalMentoring
          } else {
            data = {
              reason: sanitationCustomerNoContactReason,
            }
            method = this.kanbanService.postponeAdditional
          }

          this.isLoading = true

          method(this.card.id, data).then(() => {
            this.card.column = 'refresh' // força a retirada do card do kanban
            this.$emit('kanban-refresh')
            this.isLoading = false
            this.hide()
          }).catch(response => {
            if (response.response.data.errors[1]) {
              this.toast('Erro!', response.response.data.errors[1], false)
            } else {
              this.toast('Erro!', 'Não foi possível registrar a tentativa de contato', false)
            }
          }).finally(() => {
            this.isLoading = false
          })
        })
      }
    },

    async registerContact() {
      this.isLoading = true
      this.$emit('prevent-close-sidebar', true)

      if (this.showDemographics) {
        this.annotation = this.$refs.userListDemographic.results.notes
        const response = await this.$refs.userListDemographic.postDemographic().catch(() => {
          this.isLoading = false
        })

        if (response.status !== 200) {
          this.toast('Erro!', 'Erro ao salvar os dados demográficos', false)
          this.isLoading = false
          return
        }
      }

      let { contactMade } = this
      if (this.card.role !== 'sales_sdr') {
        contactMade = (this.contactMade === 'yes' || this.contactMade === 'new') ? 1 : 0
      }

      this.kanbanService.postRegisterContact(
        this.card.user_id,
        this.card.role,
        this.card.column,
        contactMade,
        this.annotation,
        this.annotationChannel,
        this.annotationAssignTo === 'empty' ? null : this.annotationAssignTo,
        this.dateNtim,
        this.salesContact,
        this.annotationBias,
        this.salesLeadQualification,
        this.description,
        this.salesPipeline,
      ).then(response => {
        if (response.status === 200) {
          if ((
            this.card.role === 'relationship' && this.card.column === 'onboarding'
          ) || (
            response.data.data.kanban.hide_until && (new Date(response.data.data.kanban.hide_until)) > (new Date())
          ) || (
            this.card.column !== response.data.data.kanban.column
          )) {
            this.$emit('kanban-refresh')
          }

          this.isLoading = false
        } else {
          this.toast('Erro!', 'Não foi possível salvar a anotação', false)
        }
      }).catch(error => {
        const errorMessage = error.response.data.errors[1]

        if (errorMessage) {
          this.toast('Erro!', errorMessage, false)
        } else {
          this.toast('Erro!', 'Não foi possível salvar a anotação', false)
        }
      }).finally(() => {
        this.isLoading = false
        this.$emit('prevent-close-sidebar', false)
        this.hide()
      })
    },

    handleRegisterContact() {
      if (this.card.role === 'mentoring') {
        this.$refs.rulesForm.validate().then(success => {
          if (success) {
            this.isLoading = true
            this.kanbanService.postSidebar(
              this.card.role,
              this.card.column,
              {
                user_id: this.card.user_id,
                contact_made: this.contactMade,
                description: this.annotation !== '' ? this.annotation : this.description,
                new_date: this.dateNtim ? `${this.dateNtim} ${this.dateTime}:00` : '',
              },
            ).then(response => {
              if (response.status === 200) {
                this.card.column = 'refresh'
                this.$emit('kanban-refresh')
              } else {
                this.toast('Erro!', 'Não foi possível marcar como "sem contato"', false)
              }
            }).finally(() => {
              this.isLoading = false
              this.hide()
            })
          } else {
            this.toast('Erro de validação', 'Preencha corretamente os campos', false)
          }
        })

        return false
      }

      if (this.contactMade === 'hide-until') {
        this.isLoading = true
        this.kanbanService.hideUntil(this.card.user_id, `${this.dateNtim} ${this.dateTime}`, this.description).then(response => {
          if (response.status === 200) {
            this.card.column = 'refresh'
            this.$emit('kanban-refresh')
          } else {
            this.toast('Erro!', 'Não foi possível marcar como "sem contato"', false)
          }
        }).finally(() => {
          this.isLoading = false
          this.hide()
        })
        return false
      }

      if (this.card.role === 'relationship' && this.card.column === 'schedule') {
        if (this.contactMade === 'schedule') {
          this.$refs.rulesDateTime.validate().then(success => {
            if (success) {
              this.isLoading = true
              this.kanbanService.addSchedule(this.card.user_id, `${this.dateNtim} ${this.dateTime}`, this.description).then(response => {
                if (response.status === 200) {
                  this.card.column = 'refresh' // força a retirada do card do board. pois o contato "não feito" não move ele de coluna, mas deve retirar do card
                  this.$emit('kanban-refresh')
                } else {
                  this.toast('Erro!', 'Não foi possível marcar como "sem contato"', false)
                }
              }).catch(error => {
                const errorMessage = error.response.data.errors[1]

                if (errorMessage) {
                  this.toast('Erro!', errorMessage, false)
                } else {
                  this.toast('Erro!', 'Erro ao salvar horário agendado no Agendador', false)
                }
              }).finally(() => {
                this.isLoading = false
                this.hide()
              })
            } else {
              this.toast('Erro de validação', 'Preencha corretamente os campos', false)
            }
          })

          return false
        }

        if (this.contactMade === 'schedule-no') {
          this.isLoading = true
          this.kanbanService.postScheduleNoContact(this.card.user_id, this.dateNtim).then(response => {
            if (response.status === 200) {
              this.card.column = 'refresh' // força a retirada do card do board. pois o contato "não feito" não move ele de coluna, mas deve retirar do card
              this.$emit('kanban-refresh')
            } else {
              this.toast('Erro!', 'Não foi possível marcar como "sem contato"', false)
            }
          }).finally(() => {
            this.isLoading = false
            this.hide()
          })

          return false
        }

        if (this.contactMade === 'do-not-want-onboarding') {
          this.isLoading = true
          this.kanbanService.doNotWantOnboarding(this.card.user_id).then(response => {
            if (response.status === 200) {
              this.card.column = 'refresh' // força a retirada do card do board. pois o contato "não feito" não move ele de coluna, mas deve retirar do card
              this.$emit('kanban-refresh')
            } else {
              this.toast('Erro!', 'Não foi possível marcar como "sem contato"', false)
            }
          }).finally(() => {
            this.isLoading = false
            this.hide()
          })

          return false
        }
      }

      if (this.card.role === 'sales_sdr' && this.contactMade === 'cancel-registration') {
        this.isLoading = true
        this.kanbanService.salesSdrCancelRegistration(this.card.user.id).then(response => {
          if (response.status === 200) {
            this.card.column = 'refresh' // força a retirada do card do board. pois o contato "não feito" não move ele de coluna, mas deve retirar do card
            this.$emit('kanban-refresh')
          } else {
            this.toast('Erro!', 'Não foi possível finalizar o card', false)
          }
        }).finally(() => {
          this.isLoading = false
          this.hide()
        })

        return false
      }

      if (this.card.role === 'sales_sdr' && ['yes', 'future-schedule'].includes(this.contactMade)) {
        this.$refs.rulesContactMade.validate().then(success => {
          if (success) {
            this.isLoading = true
            const data = {
              user_id: this.card.user_id,
              contact_made: this.contactMade,
              annotation: this.annotation,
              should_qualify: this.salesContact,
              lead_qualification: `${this.leadQualification}${this.leadUseInterest ? '+' : ''}`,
              hide_until: this.contactMade === 'future-schedule' ? this.dateNtim : null,
            }
            this.kanbanService.salesSdrRegisterContact(this.card.column, data).then(response => {
              if (response.status === 200) {
                this.card.column = 'refresh' // força a retirada do card do board. pois o contato "não feito" não move ele de coluna, mas deve retirar do card
                this.$emit('kanban-refresh')
              } else {
                this.toast('Erro!', 'Não foi possível mover o card', false)
              }
            }).finally(() => {
              this.isLoading = false
              this.hide()
            })
          } else {
            this.toast('Erro de validação', 'Preencha corretamente os campos', false)
          }
        })

        return false
      }

      if (this.contactMade === 'add-schedule') {
        this.isLoading = true
        this.description = ''
        this.kanbanService.addManualSchedule(this.card.user_id, this.description).then(response => {
          if (response.status === 200) {
            this.card.column = 'refresh' // força a retirada do card do board. pois o contato "não feito" não move ele de coluna, mas deve retirar do card
            this.$emit('kanban-refresh')
          } else {
            this.toast('Erro!', 'Não foi possível mover o card', false)
          }
        }).finally(() => {
          this.isLoading = false
          this.hide()
        })

        return false
      }

      if (this.card.role === 'service_qualification' && this.card.column === 'smarties') {
        this.isLoading = true
        this.userService.disableSmarties(this.card.user.id).then(response => {
          if (response.status === 200) {
            this.card.column = 'refresh'
            this.$emit('kanban-refresh')
          } else {
            this.toast('Erro!', 'Não foi possível mover o card', false)
          }
        }).finally(() => {
          this.isLoading = false
          this.hide()
        })

        return false
      }

      if (this.contactMade === 'yes') {
        this.$refs.rulesContactMade.validate().then(success => {
          if (success) {
            this.registerContact()
          } else {
            this.toast('Erro de validação', 'Preencha corretamente os campos', false)
          }
        })
      } else {
        this.registerContact()
      }

      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
